/** @jsx jsx */

import { Styled, jsx, Flex, Box } from "theme-ui"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Img from 'gatsby-image'

import SEO from "../components/seo"

import BasicHero from '../components/BasicHero'
import BgImage from '../components/BgImage'
import Card from '../components/Card'

import { Container } from '../components/item'



export const query = graphql`
  query {
    hero: file(relativePath: { eq: "Raptect-about-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blueBackground: file(relativePath: { eq: "blue-background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carImage1: file(relativePath: { eq: "about-car-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    houseBackground: file(relativePath: { eq: "carinblack.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const AboutPage = ({data}) => (

  <Layout>
    <SEO title="About Raptect" />
    
    <BasicHero 
      fluid={data.hero.childImageSharp.fluid}  
      title={"boat sailing"} 
      heroTitle={"About Raptect"}
      shapeColor={'transparent'}
    />

    <Box as="section" color='black'>
      <Container py={13} sx={{textAlign: 'center'}}>
        <Box sx={{maxWidth: '880px', margin: '0 auto'}}> 
          <Styled.h2 sx={{marginBottom: 8}}>Your Solar Protection Partner</Styled.h2>
          <Styled.p>Raptect is here to set a new standard in window tint. We offer our clients the best in quality, experience, and customer service.</Styled.p> 
        </Box>
      </Container>
    </Box>

    
    <Box as="section" bg={'green'} color={'white'}>
      <Container py={13}>
        <Flex sx={{alignItems: 'center', width: '100%', flexDirection: ['column-reverse', 'row']}}>
          <Box sx={{width: '100%', flex: ['1 1 100%', '1 1 489px'], marginTop: ['40px', 0]}}>
          <Img fluid={data.carImage1.childImageSharp.fluid} />
          </Box>
          <Box sx={{flex: ['1 1 100%', '1 1 60%'], marginLeft: [0, '80px']}}>
          <Styled.h2>Our Products</Styled.h2>
          <Styled.p>We provide expert, professional solutions for vehicles, homes, and commercial buildings. Raptect’s state-of-the-art heat rejection technology is backed by over 20 years of experience and testing. The result? The best and longest-lasting window tint available anywhere.</Styled.p>
          </Box>
        </Flex>
      </Container>
    </Box>
    
    <BgImage
      fluid={data.houseBackground.childImageSharp.fluid}
      title="stock house image"
      overlayGradient={'linear-gradient(90deg, #000000 40.1%, rgba(0, 0, 0, 0) 100%)'}
      >
      <Container py={13}>
        <Flex py={13} justifyContent={'flex-start'} w={'100%'}>
          <Box color={'white'} sx={{maxWidth: ['unset', '50%']}}>
          <Styled.h2>Our Team</Styled.h2>
          <Styled.p>Our experienced, knowledgeable team emphasizes quality and made-to-measure customer service. Our clients can expect nothing less.</Styled.p>
          </Box>
        </Flex>
      </Container>
    </BgImage>
    
    <BgImage
      fluid={data.blueBackground.childImageSharp.fluid}
      title="abstract background"
    >
      <Container py={13} sx={{textAlign: 'center', color: 'white', backgroundColor: ['blue', 'transparent']}}>
        <Styled.h2 sx={{marginY: '40px'}}>The Raptect Advantage</Styled.h2>
        <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: 'center' }}>
          <Card
            color='white'
            title="Cool, comfortable protection"
            titleColor='white'
            text="Cooling comfort and protection from the sun’s harmful rays"
          >
          </Card>
          <Card
            color='white'
            title="Safe, secure, dependable"
            titleColor='white'
            text="You can depend on raptect window films to protect your safety and privacy"
          >
          </Card>
          <Card
            color='white'
            title="Accurate, refined, detailed"
            titleColor='white'
            text="Our films apply accurately and imperceptibly for a sophisticated look"
          >
          </Card>
        </Flex>
      </Container>
    </BgImage>
    
    
  
  </Layout>
)

export default AboutPage
